import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './AuthPage.module.css';
import styles from './AuthPage.module.css';
import { API_BASE_URL } from '../config';
import AuthService from '../services/AuthService';

const AuthPage = ({ setIsLoggedIn, checkLoginStatus }) => {
    const [isLogin, setIsLogin] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [name, setName] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    const popupTimeout = 10000;

    // Add this useEffect to handle query parameters
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const message = params.get('message');
        const error = params.get('error');
        const email = params.get('email');
        if (message) {
            let displayMessage = '';
            switch (message) {
                case 'verification_success':
                    displayMessage = 'Email verified successfully! Please login.';
                    break;
                case 'already_verified':
                    displayMessage = 'Email already verified. Please login.';
                    break;
                default:
                    displayMessage = message;
            }
            setIsError(false);
            setErrorMessage(displayMessage);
            setShowPopup(true);
            setIsLogin(true);
            setEmail(email || '');
        } else if (error) {
            let displayError = '';
            switch (error) {
                case 'invalid_token':
                    displayError = 'Invalid or expired verification link.';
                    break;
                case 'user_not_found':
                    displayError = 'User not found.';
                    break;
                default:
                    displayError = error;
            }
            setIsError(true);
            setErrorMessage(displayError);
            setShowPopup(true);
        }

        // Clear query parameters from URL
        if (message || error) {
            navigate(location.pathname, { replace: true });
            setTimeout(() => {
                setShowPopup(false);
                setErrorMessage('');
            }, popupTimeout);
        }
    }, [location, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        // Add password validation for signup
        if (!isLogin && password !== confirmPassword) {
            setIsError(true);
            setErrorMessage("Passwords don't match");
            setShowPopup(true);
            setTimeout(() => {
                setShowPopup(false);
                setErrorMessage('');
            }, popupTimeout);
            return;
        }

        const endpoint = isLogin ? `${API_BASE_URL}/login` : `${API_BASE_URL}/signup`;
        const data = isLogin ? { email, password } : { name, email, password };

        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                const result = await response.json();
                console.log(isLogin ? 'Logged in successfully' : 'Signed up successfully', result);

                if (isLogin) {
                    // Login flow remains the same
                    AuthService.setTokens(result.accessToken, result.refreshToken);
                    await checkLoginStatus();
                    setShowPopup(true);
                    setTimeout(() => {
                        setShowPopup(false);
                        navigate('/');
                    }, popupTimeout);
                } else {
                    // New signup success flow
                    setIsError(false);
                    setErrorMessage('Please check your email for a verification link.');
                    setShowPopup(true);
                    setIsLogin(true); // Switch to login form
                    // Keep the email field filled
                    setPassword(''); // Clear password for security
                    setTimeout(() => {
                        setShowPopup(false);
                        setErrorMessage('');
                    }, popupTimeout);
                }
            } else {
                const error = await response.json();
                console.error(isLogin ? 'Login failed' : 'Signup failed', error);
                if (error.error) {
                    setIsError(true);
                    setErrorMessage(error.error);
                    setShowPopup(true);
                    setTimeout(() => {
                        setShowPopup(false);
                        setErrorMessage('');
                    }, popupTimeout);
                }
            }
        } catch (error) {
            console.error('An error occurred:', error);
            setIsError(true);
            setErrorMessage('An error occurred');
            setShowPopup(true);
            setTimeout(() => {
                setShowPopup(false);
                setErrorMessage('');
            }, popupTimeout);
        }
    };

    return (
        <div className={styles['auth-page']}>
            {showPopup && (
                <div className={`${styles['popup']} ${isError ? styles['error-popup'] : styles['success-popup']}`}>
                    {errorMessage || 'Login successful!'}
                </div>
            )}
            <div className={styles['auth-container']}>
                <h2>{isLogin ? 'Login' : 'Sign Up'}</h2>
                <form onSubmit={handleSubmit}>
                    {!isLogin && (
                        <>
                            <input
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </>
                    )}
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    {!isLogin && (
                        <>
                            <input
                                type="password"
                                placeholder="Confirm Password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                        </>
                    )}
                    <button type="submit">{isLogin ? 'Login' : 'Sign Up'}</button>
                </form>
                <p>
                    {isLogin ? "Don't have an account? " : "Already have an account? "}
                    <button onClick={() => setIsLogin(!isLogin)}>
                        {isLogin ? 'Sign Up' : 'Login'}
                    </button>
                </p>
            </div>
        </div>
    );
};

export default AuthPage;
